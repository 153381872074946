import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

const RequestResumeCallout = ({ content }) => {
  const { text, button } = content[0];

  return (
    <Wrapper>
      <Title>{text}</Title>
      <ButtonWrapper dangerouslySetInnerHTML={{ __html: button.text }} />
    </Wrapper>
  );
};

export default RequestResumeCallout;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  position: relative;
  justify-content: space-between;
  background-color: var(--color-white);
  padding: var(--space-m) var(--space-s);
  overflow: hidden;
  margin-top: var(--space-m);

  @media (${bp.min.sm_md}) {
    display: flex;
    align-items: center;
    padding: var(--space-l) var(--space-l);
  }

  @media (${bp.min.md}) {
    padding: var(--space-xl) 5.6rem;
  }
`;

const Title = styled.h2`
  font-size: clamp(1.8rem, 1.75rem + 1vw, 2.8rem);
  line-height: 1.2;
  letter-spacing: -0.15rem;
  margin-bottom: var(--space-s);

  @media (${bp.min.sm_md}) {
    font-weight: var(--font-weight-bold);
    margin-right: var(--space-l);
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    transition: all 150ms var(--ease-cubic-in-out);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-navy) !important;
        color: var(--color-snow) !important;

        &:after {
          content: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 21 22' fill='none' stroke='%23F6F5ED' strokeWidth='1' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EArrow%3C/title%3E%3Cpath d='M11 0.927124L20 10.9091L11 20.8577M20 10.8924H0' strokeMiterlimit='10' /%3E%3C/svg%3E");
          transform: translateX(0.4rem);
        }
      }
    }

    &:after {
      content: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 21 22' fill='none' stroke='%23172A35' strokeWidth='1' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EArrow%3C/title%3E%3Cpath d='M11 0.927124L20 10.9091L11 20.8577M20 10.8924H0' strokeMiterlimit='10' /%3E%3C/svg%3E");
      margin-left: var(--space-xs);
      transition: all 150ms var(--ease-cubic-in-out);
      height: 1.6rem;
    }
  }
`;
