import React, { useEffect, useContext } from 'react';
import { graphql, Script } from 'gatsby';
import styled from 'styled-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import InnerHTML from 'dangerously-set-html-content';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';
import MoreResources from '../components/MoreResources';

import Seo from '../components/Seo';
import UploadResumeCallout from '../components/UploadResumeCallout';
import RequestResumeCallout from '../components/RequestResumeCallout';

const JobsPage = ({ data }) => {
  const document = data.prismicJobs;
  const {
    subheader,
    truejob_embed,
    upload_resume_callout,
    resume_request_callout,
  } = document.data;

  const { setStoryHeaderColor, setPageBgColor } = useContext(GlobalContext);

  const showTypeformScript =
    upload_resume_callout[0].callout_visibility ||
    resume_request_callout[0].callout_visibility;

  useEffect(() => {
    setPageBgColor('var(--color-snow)');
    setStoryHeaderColor('var(--color-snow)');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Intro>
        <Title>
          <svg viewBox="0 0 60.4 11.1">
            <text x="0" y="10.9">
              Job Board
            </text>
          </svg>
        </Title>
        <Subtitle>{subheader}</Subtitle>
      </Intro>

      <Embed>
        <InnerHTML html={truejob_embed.text} />
      </Embed>

      {upload_resume_callout[0].callout_visibility && (
        <UploadResumeCallout content={upload_resume_callout} />
      )}

      {resume_request_callout[0].callout_visibility && (
        <RequestResumeCallout content={resume_request_callout} />
      )}

      {showTypeformScript && (
        <Script id="typeform" src="//embed.typeform.com/next/embed.js" />
      )}

      <MoreResources content={document.data} />
    </>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => (
  <Seo
    title={data.prismicJobs.data.title}
    description={data.prismicJobs.data.meta_description}
    image={data.prismicJobs.data.meta_image.url}
  />
);

export default withPrismicPreview(JobsPage);

/**
 * Styled Components
 *
 */
const Intro = styled.section`
  margin-top: 10.8rem;
  margin-bottom: var(--space-l);

  @media (${bp.min.sm_md}) {
    position: relative;
    border-bottom: 0.1rem solid ${props => props.contrastingColor};
    padding-top: 8.8rem;
    margin-top: 0;
  }

  &:after {
    @media (${bp.min.sm_md}) {
      content: '';
      width: 100%;
      border-bottom: 0.1rem solid ${props => props.contrastingColor};
      position: absolute;
      bottom: 0.4rem;
      transition: border-color 300ms var(--ease-cubic-in-out);
    }
  }
`;

const Title = styled.h1`
  display: flex;

  @media (${bp.min.sm_md}) {
    padding: var(--space-l) 0;
  }

  svg {
    width: 100%;
    font-family: var(--font-family-national-condensed);
    font-weight: var(--font-weight-extrabold);
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: -0.02rem;
    fill: var(--color-navy);
  }
`;

const Subtitle = styled.p`
  display: none;
  font-weight: var(--font-weight-bold);
  font-size: clamp(4.4rem, 3.788rem + 3.06vw, 7rem);
  line-height: 0.85;
  letter-spacing: -0.4rem;
  margin-bottom: var(--space-l);

  @media (${bp.min.sm_md}) {
    display: block;
  }
`;

const Embed = styled.div`
  width: 100%;
  margin-bottom: var(--space-m);

  @media (${bp.min.sm_md}) {
    margin-bottom: var(--space-xl);
  }

  iframe {
    width: 100%;
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query {
    prismicJobs {
      _previewable
      data {
        title
        subheader
        truejob_embed {
          text
        }
        upload_resume_callout {
          callout_visibility
          text {
            text
          }
          button {
            text
          }
        }
        resume_request_callout {
          callout_visibility
          text
          button {
            text
          }
        }
        resources_title
        resources {
          resource {
            document {
              ... on PrismicStory {
                id
                data {
                  publish_date(formatString: "MM.DD.YY")
                  title
                  featured_image {
                    alt
                    gatsbyImageData(width: 600)
                  }
                }
                first_publication_date(formatString: "MM.DD.YY")
                url
              }
              ... on PrismicBasicPage {
                id
                data {
                  publish_date(formatString: "MM.DD.YY")
                  title
                  featured_image {
                    alt
                    gatsbyImageData(width: 600)
                  }
                }
                first_publication_date(formatString: "MM.DD.YY")
                url
              }
            }
          }
        }
        meta_description
        meta_image {
          url
        }
      }
    }
  }
`;
