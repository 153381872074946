import React from 'react';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

const UploadResumeCallout = ({ content }) => {
  const { text, button } = content[0];

  return (
    <Wrapper>
      <TextWrapper>
        <Title>{text.text}</Title>
        <ButtonWrapper dangerouslySetInnerHTML={{ __html: button.text }} />
      </TextWrapper>

      <DecorativeTitle>
        <svg
          width="603"
          height="241"
          viewBox="0 0 603 241"
          fill="var(--color-navy-light)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Jobs</title>
          <path d="M45.1117 188.368C37.0157 188.368 32.4397 184.144 27.8637 171.12L0.0556641 209.488C7.44766 228.848 28.2157 240.816 53.5597 240.816C96.1517 240.816 119.384 216.528 119.384 172.528V2.8641H58.8397V170.416C58.8397 182.736 54.6157 188.368 45.1117 188.368Z" />
          <path d="M130.705 120.432C130.705 198.928 159.217 240.816 212.721 240.816C265.873 240.816 294.385 198.928 294.385 120.432C294.385 42.6401 265.873 0.0480957 212.721 0.0480957C159.569 0.0480957 130.705 42.6401 130.705 120.432ZM231.729 120.432C231.729 172.176 226.801 190.48 212.721 190.48C198.641 190.48 193.361 171.472 193.361 120.432C193.361 69.3921 198.641 50.3841 212.721 50.3841C226.801 50.3841 231.729 68.6881 231.729 120.432Z" />
          <path d="M448.234 64.8161C448.234 25.0401 424.65 2.8641 381.002 2.8641H307.082V238H379.242C428.17 238 453.866 215.12 453.866 173.232C453.866 143.664 440.49 124.304 415.498 118.32V117.616C436.618 111.984 448.234 93.3281 448.234 64.8161ZM374.314 50.3841C385.93 50.3841 390.858 57.4241 390.858 74.3201C390.858 91.5681 385.93 98.9601 374.314 98.9601H364.458V50.3841H374.314ZM376.426 140.848C388.746 140.848 393.674 148.24 393.674 165.488C393.674 183.792 388.746 190.832 376.426 190.832H364.458V140.848H376.426Z" />
          <path d="M602.053 172.88C602.053 154.928 596.773 139.792 585.509 126.064C578.117 116.208 569.669 108.464 547.141 94.0321C534.821 85.9361 526.725 79.6001 523.205 75.3761C519.685 71.1521 517.925 66.9281 517.925 62.3521C517.925 53.5521 523.557 47.2161 532.005 47.2161C542.213 47.2161 548.901 59.1841 549.253 77.4881L600.293 66.2241C598.533 24.3361 573.541 0.0480957 531.653 0.0480957C510.181 0.0480957 492.581 6.3841 478.853 19.4081C465.477 32.0801 458.789 48.6241 458.789 69.0401C458.789 82.7681 462.309 95.0881 468.997 106C476.037 116.912 490.117 129.232 510.885 142.96C523.909 151.76 532.357 158.448 536.229 163.376C540.101 167.952 542.213 172.88 542.213 178.16C542.213 187.664 536.933 192.944 527.429 192.944C514.757 192.944 506.309 179.216 506.661 158.448L455.621 172.88C457.381 214.064 485.893 240.816 528.133 240.816C549.605 240.816 567.557 234.48 581.285 222.16C595.013 209.488 602.053 192.944 602.053 172.88Z" />
        </svg>
      </DecorativeTitle>
    </Wrapper>
  );
};

export default UploadResumeCallout;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  position: relative;
  justify-content: space-between;
  background-color: var(--color-navy);
  padding: var(--space-l) var(--space-s) 0;
  overflow: hidden;

  @media (${bp.min.sm_md}) {
    display: flex;
    align-items: center;
    padding: var(--space-l) var(--space-l) var(--space-xl);
  }

  @media (${bp.min.md}) {
    padding: var(--space-l) 5.6rem var(--space-xl);
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 8.8rem;

  @media (${bp.min.sm_md}) {
    width: 75%;
    max-width: 68rem;
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  font-size: clamp(3.6rem, 3.428rem + 0.74vw, 4.2rem);
  line-height: 1.1;
  letter-spacing: -0.2rem;
  color: var(--color-baybee-blue);
  margin-bottom: var(--space-l);

  @media (${bp.min.sm_md}) {
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--space-m);
  }
`;

const DecorativeTitle = styled.div`
  display: flex;
  opacity: 0.1;
  width: 96vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -48vw;
  margin-right: -48vw;

  @media (${bp.min.sm_md}) {
    width: initial;
    opacity: 0.08;
    position: absolute;
    top: 50%;
    left: initial;
    right: var(--space-l);
    margin: 0;
    transform: translateY(-50%);
  }

  svg {
    width: 100%;
    height: auto;
  }
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    align-items: center;
    transition: all 150ms var(--ease-cubic-in-out);

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-baybee-blue) !important;
        color: var(--color-navy) !important;

        &:after {
          stroke: var(--color-navy);
          transform: translateX(0.4rem);
        }
      }
    }

    &:after {
      content: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 21 22' fill='none' stroke='%23172A35' strokeWidth='1' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EArrow%3C/title%3E%3Cpath d='M11 0.927124L20 10.9091L11 20.8577M20 10.8924H0' strokeMiterlimit='10' /%3E%3C/svg%3E");
      margin-left: var(--space-xs);
      transition: all 150ms var(--ease-cubic-in-out);
      height: 1.6rem;
    }
  }
`;
