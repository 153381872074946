import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

import ArrowIcon from './Icons/ArrowIcon';
import Image from './Image';

const Resource = ({ resource }) => {
  const document = resource.resource.document;
  const { first_publication_date, url } = document;
  const { publish_date, title, featured_image } = document.data;

  return (
    <ResourceWrapper to={url}>
      <TextWrapper>
        <ResourceDate>
          {publish_date ? publish_date : first_publication_date}
        </ResourceDate>

        <ResourceTitle>{title}</ResourceTitle>

        <ResourceButton>
          Read the Article <ArrowIcon size={12} stroke="var(--color-navy)" />
        </ResourceButton>
      </TextWrapper>

      {featured_image.gatsbyImageData !== null && (
        <ImageWrapper>
          <Image image={featured_image} aspectRatio={1 / 1} />
        </ImageWrapper>
      )}
    </ResourceWrapper>
  );
};

const MoreResources = ({ content }) => {
  const { resources_title, resources } = content;

  return (
    <Wrapper>
      <Title>{resources_title}</Title>

      {resources.map((resource, i) => (
        <Resource key={i} resource={resource} />
      ))}
    </Wrapper>
  );
};

export default MoreResources;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  padding: 12rem 0 var(--space-s);

  @media (${bp.min.xs_sm}) {
    padding: 4.8rem 0;
  }
`;

const Title = styled.h2`
  font-size: clamp(2.8rem, 11vw, 6rem);
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -0.2rem;
  text-align: center;
  padding-bottom: var(--space-s);
  margin-bottom: 5.6rem;
  border-bottom: 0.5rem solid var(--color-navy);

  @media (${bp.min.xs_sm}) {
    font-size: 2.2rem;
    letter-spacing: -0.04rem;
    font-weight: var(--font-weight-bold);
    text-align: left;
    margin-bottom: var(--space-l);
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const ResourceWrapper = styled(GatsbyLink)`
  display: flex;
  flex-direction: column-reverse;
  padding: var(--space-xs);
  background-color: var(--color-white);
  transition: background-color 150ms var(--ease-cubic-in-out);

  & + & {
    margin-top: var(--space-m);

    @media (${bp.min.xs_sm}) {
      margin: 0;
    }
  }

  &:hover {
    color: var(--color-navy);
  }

  @media (${bp.min.xs_sm}) {
    display: grid;
    grid-template-columns: 1fr 20rem;
    grid-gap: 4.8rem;
    padding: 4.8rem 2rem;
    background-color: var(--color-snow);
    border-bottom: 0.1rem solid var(--color-navy);

    h3,
    div {
      color: var(--color-navy);
      transition: color 150ms var(--ease-cubic-in-out);
    }

    &:hover {
      background-color: var(--color-white);

      h3,
      div {
        color: var(--color-great-blue);
      }
    }
  }
`;

const ResourceDate = styled.div`
  font-size: 1.2rem;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  margin-bottom: var(--space-xxxs);

  @media (${bp.min.xs_sm}) {
    display: none;
  }
`;

const TextWrapper = styled.div`
  border-bottom: 0.1rem solid var(--color-navy);
  padding-bottom: var(--space-s);
  margin: 2rem var(--space-xxs) var(--space-s);

  @media (${bp.min.xs_sm}) {
    display: flex;
    flex-direction: column;
    border-bottom: 0;
    padding-bottom: 0;
    margin: 0;
  }
`;

const ResourceTitle = styled.h3`
  font-size: clamp(2.8rem, 8vw, 9rem);
  line-height: 1.1;
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-m);
  width: 100%;
  letter-spacing: -0.04em;

  @media (${bp.min.xs_sm}) {
    line-height: 0.88;
    margin-bottom: var(--space-s);
    max-width: 96rem;
  }
`;

const ResourceButton = styled.div`
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: -0.04rem;
  color: var(--color-navy);
  display: flex;
  align-items: center;

  @media (${bp.min.xs_sm}) {
    font-size: 1.2rem;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    margin-top: auto;
  }

  svg {
    margin-left: var(--space-xxs);

    @media (${bp.min.xs_sm}) {
      display: none;
    }
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-self: flex-start;

  img {
    max-width: 100%;
  }
`;
